import { useEffect, useLayoutEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

function deleteCookie(name: string) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Transforms a number into human-readable format with delimiters every three digits
 * @param {number} number - number that will be delimited
 * @param {('.'|',')} [delimiter='.'] - the delimiter that will be used
 * @returns {string} - the number with the delimiter
 * @example
 * // returns '12,345,678.91'
 * delimit(12345678.91, ',')
 *
 * // returns '12.345.678,91'
 * delimit(12345678.91, '.')
 */
function delimit(number: number, delimiter: '.' | ',' = '.'): string {
  const rgx = /\B(?=(\d{3})+(?!\d))/g

  let result = ''

  const integerPart = Math.trunc(number)
  const decimalPart = parseFloat((number % 1).toFixed(2))
  const decimalDelimiter = delimiter === '.' ? ',' : '.'

  result = result + integerPart.toString().replace(rgx, delimiter)

  if (decimalPart)
    result = result + decimalDelimiter + decimalPart.toString().split('.')[1]

  return result
}

function getInitials(name: string): string {
  const initials = name.match(/\b\w/g) || []
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
}

const copyToClipboard = async (str: string) => {
  try {
    await navigator.clipboard.writeText(str)
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

const maskPhoneNumber = (phone: string) => {
  const maskedPhone = phone.replace(/(\d{3})(\d{8})/, '$1xxxxxx')
  return maskedPhone
}

const getPhonePreview = (phone: string) => {
  if (phone.startsWith('0')) {
    return phone.replace(/^0?/, '+62')
  }

  return phone
}

export type MediaType = 'desktop' | 'tablet' | 'mobile' | 'default'
export const mediaQueries = new Map<
  MediaType,
  Parameters<typeof useMediaQuery>[0]
>([
  ['desktop', { minWidth: 1024 }],
  ['tablet', { minWidth: 640, maxWidth: 1023 }],
  ['mobile', { maxWidth: 639 }],
  ['default', { minWidth: 640 }],
])

function useResponsive() {
  const [isClient, setIsClient] = useState(false)

  const isMobile = useMediaQuery(mediaQueries.get('mobile'))
  const isTablet = useMediaQuery(mediaQueries.get('tablet'))
  const isDesktop = useMediaQuery(mediaQueries.get('desktop'))

  useIsomorphicLayoutEffect(() => {
    setIsClient(true)
  }, [])

  return {
    isDesktop: isClient ? isDesktop : true,
    isTablet: isClient ? isTablet : false,
    isMobile: isClient ? isMobile : false,
  }
}

const Media = (props: { type?: MediaType; children }) => {
  const [isClient, setIsClient] = useState(false)
  const matchMedia = useMediaQuery(mediaQueries.get(props.type ?? 'default'))

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true)
  }, [])

  return isClient && matchMedia ? props.children : null
}

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
)

const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect

const stringToArray = (str: string) => {
  if (str === '') {
    return []
  }
  return str.split(',')
}

export {
  copyToClipboard,
  deleteCookie,
  delimit,
  getInitials,
  getPhonePreview,
  maskPhoneNumber,
  Media,
  stringToArray,
  useIsomorphicLayoutEffect,
  useResponsive,
  wait,
}
