export enum ERRORS {
  UNKNOWN,
  NOT_VALID,
  NOT_FOUND,
  NOT_VERIFIED,
  NOT_AUTHORIZED,
  NOT_SATISFIED,
  NULL_RESULT,
}

export class Err extends Error {
  code: ERRORS
  type: string
  detail?: unknown

  constructor(code: ERRORS, message?: string, detail?: unknown) {
    super(message ?? ERRORS[code])
    this.code = code
    this.type = ERRORS[code]
    this.detail = detail

    // Set the prototype explicitly.
    // This is for jest TS issue with extending from Error
    // See https://github.com/facebook/jest/issues/8279
    Object.setPrototypeOf(this, Err.prototype)
  }

  is(...codes: ERRORS[]): boolean {
    return codes.findIndex((c) => c === this.code) > -1
  }

  readable() {
    return this.message ?? String(this.code)
  }
}
