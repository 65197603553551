import { UserContextProvider } from 'context'
import Router from 'next/router'
import NProgress from 'nprogress'
import * as React from 'react'

import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ToastProvider } from '@wartek-id/toast'

import { useUserStore } from 'stores/user'

import '@wartek-id/design-tokens/build/hiedu/css/tokens.css'
import 'styles/global.css'

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('configs/mocks')
}

const getTokenValue = (cookie, prefix) => {
  return cookie
    .split('; ')
    .find((row) => row.startsWith(prefix))
    ?.split('=')[1]
}

const App = ({ Component, pageProps, router, jwtToken, jwtRefreshToken }) => {
  // This logic will decide between using useLayoutEffect or useEffect
  // When on client-side, the useLayoutEffect will be used to prevent blinking
  // The blinking happen because of the change of isLogin state
  const useEnhancedEffect =
    typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: Infinity,
          },
        },
      })
  )

  // On initial page, the session from server and client are different
  // To quick fix that, useEffect is used to get the updated session state
  // The isLogin on useEffect will be passed to context
  const [isLogin, setIslogin] = React.useState(false)
  const { session } = useUserStore()
  const allowedRoles = Component.auth ? Component.auth.allowedRoles : ['*']

  const isAllowed =
    (session.role && Component.auth && allowedRoles.includes(session.role)) ||
    allowedRoles.includes('*')

  useEnhancedEffect(() => {
    if (!isAllowed) {
      window.location.href = `${process.env.NEXT_PUBLIC_MBKM_URL}?logout=true`
    }

    setIslogin(!!session.token)
  }, [session])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ToastProvider autoDismiss placement="bottom-center">
          <UserContextProvider value={{ isLogin }}>
            <Component
              {...pageProps}
              key={router.route}
              jwtToken={jwtToken}
              jwtRefreshToken={jwtRefreshToken}
            />
          </UserContextProvider>
        </ToastProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}

App.getInitialProps = async (appContext) => {
  // Get the JWT token from the parent domain's cookie
  const headers = appContext.ctx.req?.headers
  const cookie = headers?.cookie || ''

  const jwtToken = getTokenValue(cookie, 'kmm.session-token=')
  const jwtRefreshToken = getTokenValue(cookie, 'kmm.session-refresh=')

  const pageProps = await (appContext.Component.getInitialProps
    ? appContext.Component.getInitialProps(appContext)
    : {})

  return { ...pageProps, jwtToken, jwtRefreshToken }
}

export default App
