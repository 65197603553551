type FetchReturnType = 'blob' | 'text' | 'json' | 'form' | 'buffer'
type Query = {
  [k: string]: string | number | boolean | null | undefined
}

const SERVICE_TIMEOUT = 60 * 1000
const SERVICE_CACHE: RequestCache = 'default'

export class ServiceModel {
  private name: string
  private BASE_URL: string
  private timeout: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected interceptor: (
    url: string,
    config: RequestInit & {
      signal?: AbortSignal
    }
  ) => Promise<unknown> = () => Promise.resolve()

  constructor(name: string, BASE_URL: string, timeout = SERVICE_TIMEOUT) {
    this.name = name
    this.BASE_URL = BASE_URL
    this.timeout = timeout
  }

  private queryToString(query: Query) {
    let Q = ''

    for (const key in query) {
      if (query[key] !== undefined) {
        Q += `&${key}=${query[key]}`
      }
    }

    return '?' + Q.slice(1)
  }

  private async fetch(
    url: string,
    config: RequestInit & {
      type?: FetchReturnType
      timeout?: number
      ignoreBaseURL?: boolean
    }
  ) {
    return Promise.resolve()
      .then(() => {
        // Rewrite url and config
        const { type, timeout, ...c } = config

        let signal: AbortSignal | undefined = undefined
        let timeoutId: number | null = null

        if (timeout || this.timeout) {
          try {
            const controller = new AbortController()
            signal = controller.signal
            timeoutId = setTimeout(
              () => controller.abort(),
              timeout || this.timeout
            ) as unknown as number
          } catch (err) {
            // Silent, leaving abort controller not called
          }
        }

        return {
          URL: (config.ignoreBaseURL ? '' : this.BASE_URL) + url,
          options: {
            ...c,
            signal,
          },
          timeoutId,
          type,
        }
      })
      .then(async ({ URL, options, timeoutId, type }) => {
        return this.interceptor(URL, options)
          .then(() => fetch(URL, options))
          .then(async (res) => {
            if (res.ok) {
              return res
            } else {
              throw await res.json()
            }
          })
          .then((res) => {
            switch (type) {
              case 'blob':
                return res.blob()
              case 'buffer':
                return res.arrayBuffer()
              case 'form':
                return res.formData()
              case 'json':
                return res.json()
              case 'text':
              default:
                return res.text()
            }
          })
          .then((data) => {
            if (timeoutId) {
              clearTimeout(timeoutId)
              timeoutId = null
            }

            return data
          })
          .catch((err) => {
            if (timeoutId) {
              clearTimeout(timeoutId)
              timeoutId = null
            }

            throw err
          })
      })
  }

  protected async get(
    url: string,
    config: {
      headers?: RequestInit['headers']
      mode?: RequestInit['mode']
      cache?: RequestInit['cache']
      query?: Query
      type?: FetchReturnType
      timeout?: number
      ignoreBaseURL?: boolean
      referrerPolicy?: RequestInit['referrerPolicy']
      credentials?: RequestInit['credentials']
    },
    token?: string
  ) {
    return this.fetch(
      url + (config.query ? this.queryToString(config.query) : ''),
      {
        method: 'GET',
        cache: config.cache ?? SERVICE_CACHE,
        mode: config.mode,
        referrerPolicy: config.referrerPolicy,
        headers: {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {}),
          ...(config.headers || {}),
        },
        type: config.type,
        timeout: config.timeout,
        ignoreBaseURL: config.ignoreBaseURL,
        credentials: config.credentials,
      }
    )
  }

  protected post(
    url: string,
    config: {
      headers?: RequestInit['headers']
      mode?: RequestInit['mode']
      cache?: RequestInit['cache']
      body?: RequestInit['body']
      redirect?: RequestInit['redirect']
      type?: FetchReturnType
      timeout?: number
      ignoreBaseURL?: boolean
    },
    token?: string
  ) {
    return this.fetch(url, {
      method: 'POST',
      cache: config.cache ?? SERVICE_CACHE,
      mode: config.mode,
      headers: {
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
        ...(config.headers || {}),
      },
      body: config.body,
      type: config.type,
      timeout: config.timeout,
      redirect: config.redirect,
      ignoreBaseURL: config.ignoreBaseURL,
    })
  }

  protected delete(
    url: string,
    config: {
      headers?: RequestInit['headers']
      mode?: RequestInit['mode']
      cache?: RequestInit['cache']
      body?: RequestInit['body']
      type?: FetchReturnType
      timeout?: number
      ignoreBaseURL?: boolean
    },
    token?: string
  ) {
    return this.fetch(url, {
      method: 'DELETE',
      cache: config.cache ?? SERVICE_CACHE,
      mode: config.mode,
      headers: {
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
        ...(config.headers || {}),
      },
      body: config.body,
      type: config.type,
      timeout: config.timeout,
      ignoreBaseURL: config.ignoreBaseURL,
    })
  }

  protected put(
    url: string,
    config: {
      headers?: RequestInit['headers']
      mode?: RequestInit['mode']
      cache?: RequestInit['cache']
      body?: RequestInit['body']
      type?: FetchReturnType
      timeout?: number
      ignoreBaseURL?: boolean
    },
    token?: string
  ) {
    return this.fetch(url, {
      method: 'PUT',
      cache: config.cache ?? SERVICE_CACHE,
      mode: config.mode,
      headers: {
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
        ...(config.headers || {}),
      },
      body: config.body,
      type: config.type,
      timeout: config.timeout,
      ignoreBaseURL: config.ignoreBaseURL,
    })
  }

  protected update(
    url: string,
    config: {
      headers?: RequestInit['headers']
      mode?: RequestInit['mode']
      cache?: RequestInit['cache']
      body?: RequestInit['body']
      type?: FetchReturnType
      timeout?: number
      ignoreBaseURL?: boolean
    },
    token?: string
  ) {
    return this.fetch(url, {
      method: 'UPDATE',
      cache: config.cache ?? SERVICE_CACHE,
      mode: config.mode,
      headers: {
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
        ...(config.headers || {}),
      },
      body: config.body,
      type: config.type,
      timeout: config.timeout,
      ignoreBaseURL: config.ignoreBaseURL,
    })
  }

  protected json(
    url: string,
    config: {
      method?: RequestInit['method']
      headers?: RequestInit['headers']
      mode?: RequestInit['mode']
      cache?: RequestInit['cache']
      body?: { [k: string]: unknown } | unknown[]
      type?: FetchReturnType
      timeout?: number
      ignoreBaseURL?: boolean
    },
    token?: string
  ) {
    return this.fetch(url, {
      method: config.method ?? 'POST',
      cache: config.cache ?? SERVICE_CACHE,
      mode: config.mode,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
        ...(config.headers || {}),
      },
      body: JSON.stringify(config.body),
      type: config.type || 'json',
      timeout: config.timeout,
      ignoreBaseURL: config.ignoreBaseURL,
    })
  }

  protected form<
    B extends {
      [k: string]: unknown
    }
  >(
    url: string,
    config: {
      method?: RequestInit['method']
      headers?: RequestInit['headers']
      mode?: RequestInit['mode']
      cache?: RequestInit['cache']
      body: B
      filenames?: {
        [key in keyof B]?: string
      }
      type?: FetchReturnType
      timeout?: number
      ignoreBaseURL?: boolean
    },
    token?: string
  ) {
    const fd = new FormData()

    Object.keys(config.body).forEach((key) => {
      const filename = config.filenames ? config.filenames[key] : undefined
      if (filename) {
        fd.append(
          String(key),
          config.body[key] as string,
          config.filenames ? config.filenames[key] : undefined
        )
      } else {
        fd.append(String(key), config.body[key] as string)
      }
    })

    return this.fetch(url, {
      method: config.method ?? 'POST',
      cache: config.cache ?? SERVICE_CACHE,
      mode: config.mode,
      headers: {
        Accept: 'application/json',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
        ...(config.headers || {}),
      },
      body: fd,
      type: config.type || 'json',
      timeout: config.timeout,
      ignoreBaseURL: config.ignoreBaseURL,
    })
  }

  protected composeGet(
    url: string,
    config: {
      query?: Query
      ignoreBaseURL?: boolean
    }
  ) {
    return (
      (config.ignoreBaseURL ? '' : this.BASE_URL) +
      url +
      (config.query ? this.queryToString(config.query) : '')
    )
  }
}
