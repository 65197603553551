import { ServiceModel } from 'utils/service'

class AccountServiceModel extends ServiceModel {
  public async login(
    email: string,
    password: string
  ): Promise<{
    data: {
      access_token: string
      refresh_token: string
      expire_at: string
    }
    meta: unknown
  }> {
    return this.json('auth/login/mbkm', {
      body: {
        email,
        password,
      },
    })
  }

  public async logout(token: string): Promise<{
    data: {
      status: true
    }
    meta: unknown
  }> {
    return this.json('auth/logout', {}, token)
  }

  public async refresh(refreshToken: string): Promise<{
    data: {
      access_token: string
      refresh_token: string
      expire_at: string
    }
    meta: unknown
  }> {
    return this.json('auth/refresh_token', {
      body: {
        refresh_token: refreshToken,
      },
    })
  }
}

export const AccountService = new AccountServiceModel(
  'Account Service',
  process.env.NEXT_PUBLIC_API_URL + '/user/'
)
