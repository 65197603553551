// On initial page, the session from server and client are different
// To quick fix that, useEffect is used to get the updated session state
// The updated session state will be passed through context

import { createContext } from 'utils/react-utils'

type UserContextType = {
  isLogin: boolean
}

export const [UserContextProvider, useUserContext] =
  createContext<UserContextType>({
    name: 'UserContext',
    strict: false,
  })
