import decoder from 'jwt-decode'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { Err, ERRORS } from 'utils/error'
import { deleteCookie } from 'utils/misc'

import { Roles } from 'configs/roles'

import { AccountService } from 'apis'

import { DecodedToken, SessionData, UserData } from './_types'

const AUTHORIZED_ROLES = [Roles.PT, Roles.KPT, Roles.DPL]

export const useUserStore = create<{
  session: SessionData
  user: UserData | null
  logged: () => boolean
  token: () => Promise<string>
  logout: () => void
  login: (email: string, password: string) => Promise<SessionData>
  setLoginInfo: (session: string, refreshToken: string) => void
}>()(
  persist(
    (set, get) => ({
      session: {},
      user: null,
      logged() {
        return !!get().session.token
      },
      async token() {
        const session = get().session
        if (
          session.refreshToken &&
          session.expiredAt &&
          session.expiredAt < +new Date()
        ) {
          return AccountService.refresh(session.refreshToken)
            .then(async (res) => {
              const { name, user_id, roles } = decoder<DecodedToken>(
                res.data.access_token
              )

              if (!AUTHORIZED_ROLES.some((role) => roles.includes(role))) {
                throw new Err(
                  ERRORS.NOT_AUTHORIZED,
                  'Anda tidak mempunyai akses untuk halaman ini'
                )
              }

              set({
                user: {
                  id: user_id,
                  name,
                },
                session: {
                  role: roles[0] as Roles.PT,
                  token: res.data.access_token,
                  refreshToken: res.data.refresh_token,
                  expiredAt: +new Date(res.data.expire_at),
                },
              })

              return res.data.access_token
            })
            .catch((err) => {
              throw new Err(ERRORS.NOT_AUTHORIZED, undefined, err)
            })
        } else if (session.refreshToken && session.expiredAt) {
          // Token still valid
          return session.token
        }
        throw new Err(ERRORS.NOT_AUTHORIZED)
      },
      async logout() {
        const AUTH_COOKIES = ['kmm.session-token', 'kmm.session-refresh']

        get()
          .token()
          .then((token) => {
            return AccountService.logout(token)
          })
          .catch(() => null)

        set({
          session: {},
          user: null,
        })

        AUTH_COOKIES.forEach((cookie) => {
          deleteCookie(cookie)
        })

        window.location.href = `${process.env.NEXT_PUBLIC_MBKM_URL}?logout=true`
      },
      async setLoginInfo(token, refreshToken) {
        const { name, user_id, exp, roles } = decoder<DecodedToken>(token)

        if (!AUTHORIZED_ROLES.some((role) => roles.includes(role))) {
          throw new Err(
            ERRORS.NOT_AUTHORIZED,
            'Anda tidak mempunyai akses untuk halaman ini'
          )
        }

        set({
          user: {
            id: user_id,
            name,
          },
          session: {
            role: roles[0] as Roles.PT,
            token,
            refreshToken,
            expiredAt: +new Date(exp * 1000),
          },
        })
      },
      async login(email: string, password: string) {
        return AccountService.login(email, password).then(async (res) => {
          const { name, user_id, roles, exp } = decoder<DecodedToken>(
            res.data.access_token
          )

          if (!AUTHORIZED_ROLES.some((role) => roles.includes(role))) {
            throw new Err(
              ERRORS.NOT_AUTHORIZED,
              'Anda tidak mempunyai akses untuk halaman ini'
            )
          }

          set({
            user: {
              id: user_id,
              name,
            },
            session: {
              role: roles[0] as Roles.PT,
              token: res.data.access_token,
              refreshToken: res.data.refresh_token,
              expiredAt: +new Date(exp * 1000),
            },
          })

          return get().session
        })
      },
    }),
    {
      name: '@kmm/user',
    }
  )
)
