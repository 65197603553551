// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  environment: process.env.NEXT_PUBLIC_ENV,
  release: `${process.env.NEXT_PUBLIC_ENV}@${
    process.env.NEXT_PUBLIC_SHA || ''
  }`,
  beforeSend(event, hint) {
    const originalException = hint.originalException
    if (
      typeof originalException === 'string' &&
      originalException.indexOf('this error can be safely ignored') >= 0
    ) {
      return null
    }
    return event
  },
  dsn:
    SENTRY_DSN ||
    'https://9bb5e2b48da24baeb63b5bb58afb925f@o1279780.ingest.sentry.io/4504477630857216',
  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: 1.0,
  sampleRate: 0.1,
  tracesSampler() {
    return false
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
